<template>
  <div class="global_wh app position_r" style="overflow-x: hidden" id="app">
    <keep-alive include="skrollr">
      <router-view />
    </keep-alive>
    <el-button
      type="primary"
      icon="el-icon-arrow-up"
      circle
      class="position_f"
      @click="changeScreen"
      style="bottom: 50px; right: 50px"
    ></el-button>
  </div>
</template>

<script>
export default {
  name: "app_page",
  methods: {
    changeScreen() {
      const dom = document.getElementById("app");
      dom.scrollTop = 0;
    },
  },
};
</script>
