import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Layout",
    component: () => import("../components/Layout"),
    redirect: "/home",
    children: [
      {
        path: "/home",
        name: "HomePage",
        component: () => import("../views/home.vue"),
      },
      {
        path: "/products/1",
        name: "ProductsPage1",
        component: () => import("../views/products/1.vue"),
      },
      {
        path: "/products/2",
        name: "ProductsPage2",
        component: () => import("../views/products/2.vue"),
      },
      {
        path: "/products/3",
        name: "ProductsPage3",
        component: () => import("../views/products/3.vue"),
      },
      {
        path: "/products/4",
        name: "ProductsPage4",
        component: () => import("../views/products/4.vue"),
      },
      {
        path: "/products/5",
        name: "ProductsPage5",
        component: () => import("../views/products/5.vue"),
      },
      {
        path: "/products/introduction",
        name: "Introduction",
        component: () => import("../views/products/introduction.vue"),
      },
      {
        path: "/terms",
        name: "TermPage",
        component: () => import("../components/Footer/terms.vue"),
      },
      {
        path: "/about/:id",
        name: "AboutPage",
        component: () => import("../components/Footer/about.vue"),
      },
      {
        path: "/contact/:id",
        name: "ContactPage",
        component: () => import("../components/Footer/contact.vue"),
      },
      {
        path: "/treaty",
        name: "treatyPage",
        component: () => import("../components/Footer/treaty.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.name == from.name) {
    return next();
  } else {
    return next();
  }
});

router.afterEach((to, from) => {
  if (to.name == from.name) {
    return;
  } else {
    const dom = document.getElementById("app");
    dom.scrollTop = 0;
    return;
  }
});
const originalPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

export default router;
